import { io } from "socket.io-client";
import { SevUrl } from "./Utility";
const isLocalhost = window.location.hostname === "localhost";
const EndPoint = isLocalhost
  ? "localhost:7771"
  : `https://baccarat-server.${SevUrl().servername}.com:${SevUrl().port}`;

// const EndPoint = `https://baccarat-server.${SevUrl().servername}.com:${
//   SevUrl().port
// }`;

const socket = io(EndPoint, {
  autoConnect: false, // 자동 연결 비활성화
  reconnectionAttempts: Infinity,
  reconnectionDelay: 1000,
  transports: ["websocket"],
});

export default socket;
