import React, { useEffect, useState } from "react";
import "./App.css";
import socket from "./socket";
import { getTranslation, SevUrl } from "./Utility";

function App() {
  const [userId, setUserId] = useState("");
  const [userPw, setUserPw] = useState("");
  const [langDropdownOpen, setLangDropdownOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("KOREAN");
  const [token, setToken] = useState("");
  const [Terms, setTerms] = useState(false);
  const paragraphs = getTranslation(selectedLanguage, "terms_text")
    .split("\n")
    .filter((line) => line.trim() !== "");

  function GoLobby() {
    window.location.href = `https://avata-client.pd-live-services.com/lobby/?token=${token}&list=414C4C&caseName=${
      SevUrl().caseName
    }`;
  }
  // 언어 변경 시 쿠키를 설정하고 선택된 언어를 업데이트하는 함수
  const changeLangCookie = (lang) => {
    document.cookie = `lang=${lang}; path=/`;

    switch (lang) {
      case "ko":
        setSelectedLanguage("KOREAN");
        break;
      case "cn":
        setSelectedLanguage("CHINESE");
        break;
      case "en":
        setSelectedLanguage("ENGLISH");
        break;
      case "jp":
        setSelectedLanguage("JAPANESE");
        break;
      default:
        setSelectedLanguage("KOREAN");
    }
    setLangDropdownOpen(false); // 언어 선택 후 드롭다운 닫기
  };

  // 언어 드롭다운 토글 함수
  const toggleDropdown = () => {
    setLangDropdownOpen(!langDropdownOpen);
  };

  // 게스트 로그인 버튼 클릭 시 호출되는 함수
  const handleGuestLogin = () => {
    window.location.href = "/guestLogin";
  };

  // 로그인 폼 제출 처리 함수
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!userId || !userPw) return;
    // userId와 userPw에 담긴 값으로 로그인 처리를 구현할 수 있습니다.

    socket.emit("LoginRequest", { id: userId, pw: userPw });
    // 예를 들어, API 호출을 통해 서버에 로그인 정보를 전송할 수 있습니다.
  };
  useEffect(() => {
    if (!socket.connected) {
      socket.connect();
    }
    socket.on("connect", () => {
      console.log("connect");
    });
    socket.on("LoginFail", () => {
      alert(getTranslation(selectedLanguage, "login_fail"));
    });
    socket.on("LoginSuccess", (data) => {
      setToken(data.mb_token);
      setTerms(true);
    });
    return () => {
      socket.off("connect");
      socket.off("LoginFail");
      socket.off("LoginSuccess");
    };
  }, [selectedLanguage]);

  return (
    <div className="overlay">
      {!Terms ? (
        <div className="form-container">
          <div className="img-box">
            <img src="./icon.png" alt="Icon" />
          </div>
          <div className="language_class">
            <button type="button" id="btn_language" onClick={toggleDropdown}>
              <div id="select_lang">{selectedLanguage}</div> <span></span>
            </button>
            <div
              className={`select ${langDropdownOpen ? "active" : ""}`}
              id="select"
            >
              <div onClick={() => changeLangCookie("ko")}>
                <img src="./language_ko.jpg" alt="Korean" /> KOREAN
              </div>
              <div onClick={() => changeLangCookie("cn")}>
                <img src="./language_ch.jpg" alt="Chinese" /> CHINESE
              </div>
              <div onClick={() => changeLangCookie("en")}>
                <img src="./language_en.jpg" alt="English" /> ENGLISH
              </div>
              <div onClick={() => changeLangCookie("jp")}>
                <img src="./language_jp.jpg" alt="Japanese" /> JAPANESE
              </div>
            </div>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="input-field">
              <input
                type="text"
                placeholder={getTranslation(selectedLanguage, "id")}
                name="user_id"
                id="user_id"
                autoComplete="off"
                value={userId}
                onChange={(e) => setUserId(e.target.value)}
              />
            </div>
            <div className="input-field">
              <input
                type="password"
                placeholder={getTranslation(selectedLanguage, "pw")}
                name="user_pw"
                id="user_pw"
                autoComplete="off"
                value={userPw}
                onChange={(e) => setUserPw(e.target.value)}
              />
            </div>
            <div className="input-field">
              <input
                type="submit"
                value={getTranslation(selectedLanguage, "login")}
                id="login_submit"
              />
            </div>
          </form>
          {false && (
            <div className="input-field">
              <button id="login_guest" onClick={handleGuestLogin}>
                {getTranslation(selectedLanguage, "guest_login")}
              </button>
            </div>
          )}
        </div>
      ) : (
        <div class="form-container2">
          <div id="agree_title">
            {getTranslation(selectedLanguage, "terms_title")}
          </div>
          <div class="form-container2_box">
            <div class="i_list" id="agree_txt">
              {paragraphs.map((para, idx) => (
                <p key={idx}>{para}</p>
              ))}
            </div>
          </div>
          <div class="i_btn">
            <div class="input-field">
              <button onClick={() => setTerms(false)}>
                <span id="agree_cancel">
                  {getTranslation(selectedLanguage, "cancel")}
                </span>
              </button>
            </div>
            <div class="input-field">
              <button onClick={() => GoLobby()}>
                <span id="agree_ok">
                  {getTranslation(selectedLanguage, "login")}
                </span>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
